'use strict';

angular.module('enervexSalesappApp').controller('ProposalTableCtrl', function($scope, $http, _, $stateParams, Util, ProposalTables, Flash) {
	ProposalTables.get({
		id: $stateParams.id
	}).$promise.then(function(table) {
		$scope.proposalTable = table
	})
	$scope.ctx = {
		formChanged: false
	}

	$scope.designTypes = ["SI", "IP"]
	$scope.selectedDesignType = "SI"

	$scope.saveTableChanges = function() {
		var payload = _.clone($scope.proposalTable);
		
		ProposalTables.update({
			id: $scope.proposalTable._id
		}, payload).$promise.then(function(res) {
			Flash.create('success', '<strong>Success!</strong> Successfully updated proposal table.', 0, {}, true);
		}).catch(function(err) {
			var message = (err.data && err.data.message) || "There was an error saving";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		})
		$scope.ctx.formChanged = false
	}

	$scope.columnHasValues = function(type, subtable) {
		if (subtable){
			return _.find(_.get(subtable, 'columnLabels'), function(c) {
				return c[type]
			})
		} else {
			return _.find(_.get($scope, 'proposalTable.columnLabels'), function(c) {
				return c[type]
			})
		}
	}

	$scope.showDesignTypeSelection = function(table) {
		if (table && table.subTables && table.subTables.length > 0) {
			var displayFeature = false
			_.each(table.subTables, function(st) {
				_.each(_.get(st, 'columnLabels'), function(label) {
					if (label.prefix || label.suffix) {
						displayFeature = true
					}
				})	
			})
			return displayFeature
		} else {
			return _.find(_.get(table, 'columnLabels'), function(label) {
				return label.prefix || label.suffix
			})
		}
	}

	$scope.formatUnits = function(unit, isOutput) {
		if (unit.prefixType == 'getUnits' || unit.suffixType == 'getUnits') {
			var design = {
				units: $scope.selectedDesignType
			}
			var symbol = _.get(unit, 'symbol') ? unit.symbol : ""

			return symbol + Util.getUnits(design, unit.value)
		} else if (unit.prefixType == 'designValue' || unit.suffixType == 'designValue') {
			if (isOutput) {
				return "{value}"
			} else {
				return _.get(unit, 'value')
			}
		}
	}

	$scope.computeLabel = function(item, isOutput) {
		var computedLabel = ''
		if (_.get(item, 'prefix.value')) {
			computedLabel += $scope.formatUnits(item.prefix, isOutput)
		}
		if (_.get(item, 'label')) {
			computedLabel += item.label + ' '
		}
		if (_.get(item, 'suffix.value')) {
			computedLabel += "("+ $scope.formatUnits(item.suffix, isOutput) + ") "
		}
		return computedLabel
	}
});